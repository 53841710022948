import * as React from "react"
import ContactusSection from "../components/mainpage/ContactusSection"
import FooterSection from "../components/mainpage/FooterSection"
import HeaderSection from "../components/HeaderSection"
import Menuzord from "../components/mainpage/Menuzord"
import { graphql } from "gatsby"
import Seo from "../components/seo"

const ContactPage = ({ data }) => {
  const { imageFile } = data.directus_system.files[0];
  return (
    <>
      <Seo title="Контакты"/>
      <Menuzord pageName="contact" />
      <HeaderSection backgroundImage={imageFile}>Мы были бы рады работать с вами!</HeaderSection>
      <ContactusSection />
      <FooterSection />
    </>
  )
}

export default ContactPage

export const query = graphql`
  query HeaderContact {
    directus_system {
      files(filter: {title: {_eq: "Contact Background"}}) {
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
        id
      }
    }
  }
`